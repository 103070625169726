
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

interface editSiswa {
  identityNumber: string;
  name: string;
  gender: string;
  kelas: string;
  kelasDetail: string;
  placeOfBirth: string;
  dateOfBirth: string;
  migrationStatus: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
  tags: Array<string>;
}

interface items {
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
  listSekolah: any;
  disableButton: boolean;
  erroridentityNumber: string;
  errorname: string;
  errorgender: string;
  errorprovince: string;
  errorcity: string;
  errorregency: string;
  errordetail: string;
  errordateOfBirth: string;
  errorplaceOfBirth: string;
  errorkelas: string;
  errorkelasDetail: string;
}

interface onlyId {
  schoolName: string;
}

interface cekSchool {
  cekSchool: string;
}

export default defineComponent({
  name: "Siswa",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      provinsiOptions: [],
      listSekolah: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      disableButton: false,

      erroridentityNumber: "",
      errorname: "",
      errorgender: "",
      errorprovince: "",
      errorcity: "",
      errorregency: "",
      errordetail: "",
      errordateOfBirth: "",
      errorplaceOfBirth: "",
      errorkelas: "",
      errorkelasDetail: "",
    });

    const editSiswaValidator = Yup.object().shape({
      identityNumber: Yup.string().required("Nomor Identitas Wajib Diisi"),
      name: Yup.string().required("Nama Wajib Diisi"),
      gender: Yup.string().required("Jenis Kelamin Wajib Diisi"),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kota/ Kabupaten Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
      detail: Yup.string().required("Alamat Wajib Diisi"),
      dateOfBirth: Yup.string().required("Tanggal Lahir Wajib Diisi"),
      placeOfBirth: Yup.string().required("Tempat Lahir Wajib Diisi"),
      kelas: Yup.string().required("Kelas Wajib Diisi"),
      kelasDetail: Yup.string().required("Kelas Detail Wajib Diisi"),
    });

    const editSiswa = reactive<editSiswa>({
      identityNumber: "",
      name: "",
      gender: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
      dateOfBirth: "",
      placeOfBirth: "",
      kelas: "",
      kelasDetail: "",
      tags: [],
      migrationStatus: "",
    });

    const onlyId = reactive<onlyId>({
      schoolName: "",
    });

    interface cekSchool {
      cekSchool: string;
    }

    const saveChanges1 = () => {
      if (
        editSiswa.identityNumber === "" ||
        editSiswa.name === "" ||
        editSiswa.gender === "" ||
        editSiswa.address.province === "" ||
        editSiswa.address.province == null ||
        editSiswa.address.city === "" ||
        editSiswa.address.city == null ||
        editSiswa.address.regency === "" ||
        editSiswa.address.regency == null ||
        editSiswa.address.detail === "" ||
        editSiswa.dateOfBirth === "" ||
        editSiswa.placeOfBirth === "" ||
        editSiswa.kelas === "" ||
        editSiswa.kelasDetail === ""
      ) {
        items.erroridentityNumber = "Nomor Identitas Wajib Diisi";
        items.errorname = "Nama Wajib Diisi";
        items.errorgender = "Jenis Kelamin Wajib Diisi";
        items.errorprovince = "Provinsi Wajib Diisi";
        items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        items.errorregency = "Kecamatan Wajib Diisi";
        items.errordetail = "Alamat Wajib Diisi";
        items.errordateOfBirth = "Tanggal Lahir Wajib Diisi";
        items.errorplaceOfBirth = "Tempat Lahir Wajib Diisi";
        items.errorkelas = "Kelas Wajib Diisi";
        items.errorkelasDetail = "Kelas Detail Wajib Diisi";
      } else {
        isLoading.value = true;
        // Activate loading indicator
        items.disableButton = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        ApiService.putWithData(
          "crmv2/main_student/student/" + route.params._id,
          editSiswa
        )
          .then((res) => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            toast.success("Berhasil Ubah Siswa");
            router.push({ path: "/sekolah/siswa" });

            isLoading.value = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push("/sign-in");
              isLoading.value = false;
            } else {
              toast.error(e.response.data.detail);
              items.disableButton = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              isLoading.value = false;
            }
          });
      }
    };

    const getIdSiswa = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug(
        "crmv2/main_student/student/" + route.params._id
      )
        .then((res) => {
          editSiswa.identityNumber = res.data.identityNumber;
          editSiswa.name = res.data.name;
          editSiswa.placeOfBirth = res.data.placeOfBirth;
          editSiswa.dateOfBirth = res.data.dateOfBirth;
          editSiswa.kelas = res.data.kelas;
          editSiswa.kelasDetail = res.data.kelasDetail;
          editSiswa.tags = res.data.tags;
          editSiswa.gender = res.data.gender;
          editSiswa.migrationStatus = res.data.migrationStatus;
          editSiswa.address.province = res.data.address.province;
          editSiswa.address.city = res.data.address.city;
          editSiswa.address.regency = res.data.address.regency;
          editSiswa.address.detail = res.data.address.detail;

          getTempProv();

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const pilihProv = (event) => {
      console.log(event);
      if (event == null) {
        editSiswa.address.province = "";
        editSiswa.address.city = "";
        editSiswa.address.regency = "";
        // getKabupaten();
        items.kabupatenOptions = [];
        items.kecamatanOptions = [];
      } else {
        if (event) {
          editSiswa.address.province = event;
          editSiswa.address.city = "";
          editSiswa.address.regency = "";
          items.kecamatanOptions = [];
          getKabupaten();
        } else {
          editSiswa.address.province = "";
          editSiswa.address.city = "";
          editSiswa.address.regency = "";
          // getKabupaten();
          items.kabupatenOptions = [];
          items.kecamatanOptions = [];
        }
      }
    };

    const pilihKab = (event) => {
      if (event == null) {
        editSiswa.address.city = "";
        editSiswa.address.regency = "";
        items.kecamatanOptions = [];
      } else {
        if (event) {
          editSiswa.address.city = event;
          editSiswa.address.regency = "";
          getKecamatan();
        } else {
          editSiswa.address.city = "";
          editSiswa.address.regency = "";
          items.kecamatanOptions = [];
        }
      }
    };

    const pilihKec = (event) => {
      if (event) {
        editSiswa.address.regency = event;
        getKecamatan();
      } else {
        editSiswa.address.regency = "";
        getKecamatan();
      }
      console.log(editSiswa.address.regency);
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === editSiswa.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === editSiswa.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kecamatanOptions = response.data;
        });
      }
    };

    // get Temporary
    const getTempProv = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;

        let indexProv = items.provinsiOptions.findIndex(
          (x) => x.name === editSiswa.address.province
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getAllProvinces"
        ).then((response) => {
          localStorage.setItem(
            "idProvinsi",
            JSON.stringify(response.data[indexProv].id)
          );

          getTempKab();
        });
      });
    };

    // untuk mendapatkan kabupaten dari get id area
    const getTempKab = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getRegencies/" +
          JSON.parse(localStorage.getItem("idProvinsi")!)
      ).then((response) => {
        items.kabupatenOptions = response.data;

        let indexKab = items.kabupatenOptions.findIndex(
          (x) => x.name === editSiswa.address.city
        );

        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" +
            JSON.parse(localStorage.getItem("idProvinsi")!)
        ).then((response) => {
          localStorage.setItem(
            "idKab",
            JSON.stringify(response.data[indexKab].id)
          );

          getTempKec();
        });
      });
    };

    const getTempKec = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getDistricts/" +
          JSON.parse(localStorage.getItem("idKab")!)
      ).then((response) => {
        items.kecamatanOptions = response.data;
      });
    };

    const getIdSchool = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_school/admin_school/school/" +
          JSON.parse(localStorage.getItem("user_account")!).schoolId
      ).then((response) => {
        onlyId.schoolName = response.data.name;
      });
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SISWA);

      getIdSiswa();
      getProvinsi();
      getIdSchool();
    });

    return {
      submitButton,
      saveChanges1,
      editSiswa,
      editSiswaValidator,
      onlyId,
      items,
      getIdSchool,

      getKabupaten,
      getKecamatan,
      getIdSiswa,
      getTempProv,
      getTempKab,
      getTempKec,

      pilihProv,
      pilihKab,
      pilihKec,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
